import React from "react";
import Epks from "../../assets/epks.svg";
import Grb from "../../assets/grb-srbije.svg";

const AdsDescription = () => {
    return (
        <div>
            <div
                className="grey-text text-lighten-1"
                style={{ padding: "20px", paddingTop: "0px" }}
            >
                <h2 className="light-blue-text">Uputstvo za korišćenje</h2>
                <p>
                    Da bi ste u web aplikaciji ili na portalu mogli da koristite
                    funkcionalnost potpisivanja dokumenata kvalifikovanim
                    elektronskim sertifikatom, potrebno je da na svom računaru
                    imate instalirane sledeće komponente ADS sistema:
                </p>
                <ul className="browser-default">
                    <li>ADS middleware desktop aplikaciju</li>
                    <li>
                        ADS ekstenziju za Google Chrome ili najnoviju verziju
                        Microsoft Edge browsera
                    </li>
                </ul>
                <p>Ove komponente možete da preuzmete na ovoj stranici.</p>
                <p>
                    Nakon što preuzmete middleware aplikaciju, treba da
                    raspakujete ZIP fajl i da na računaru pokrenete instalacionu
                    datoteku. Proces instalacije će vas voditi kroz neophodne
                    korake.
                </p>
                <p>
                    Ekstenziju instalirate direktno sa Goole Chrome Web Storea,
                    klikom na dugme "Add to Chrome". U slučaju da koristite
                    poslednju verziju Microsoft Edge browsera (verzija 81 ili
                    novija) postupak je identičan. Internet Explorer, Firefox i
                    starije verzije Edge programa nisu podržani.
                </p>
                <h2 className="light-blue-text">ADS sistem u praksi</h2>
                <p>
                    Pored više internih web aplikacija i portala u nekim od
                    najvećih kompanija u našoj zemlji, ADS sistem koriste i
                    javni portali:
                </p>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 center-align">
                            <a
                                href="https://usluge.pks.rs"
                                target="blank"
                                className="light-blue-text"
                            >
                                <Epks style={{ height: "100px" }} />
                                Portal za elektronske usluge
                                <br />
                                Privredne komore Srbije
                            </a>
                        </div>
                        <div className="col-12 col-md-6 center-align">
                            <a
                                href="https://app.carina.rs/cas.publicportal"
                                target="blank"
                                className="light-blue-text"
                            >
                                <Grb style={{ height: "100px" }} />
                                Javni portal za razmenu informacija
                                <br />
                                sa korisnicima Uprave Carina
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdsDescription;
