import React from "react";
import { Button } from "react-materialize";

const AdsDownload = () => {
    return (
        <div className="white-text" style={{ marginTop: "40px" }}>
            <h4 className="light-blue-text">ADS Middleware</h4>
            <p>
                ADS middleware je aplikacija koju instalirate na vaš Windows
                računar, a koja omogućuje povezivanje sa čitačem kartice
            </p>
            <Button
                className="light-blue"
                node="a"
                target="blank"
                href="http://www.thesolidus.com/ads/ads.zip"
            >
                Preuzmite ADS middleware
            </Button>
            <hr />
            <h4 className="light-blue-text">ADS Chrome ekstenzija</h4>
            <p>
                ADS Chrome ekstenzija je dodatak za Google Chrome i Microsoft
                Edge browsere za komunikaciju između portala i middlewarea
            </p>
            <Button
                className="light-blue"
                node="a"
                target="blank"
                href="https://chrome.google.com/webstore/detail/atamagai-business-solutio/pbbdbcnkbkchmlaldpjpigoikiidhaeh"
            >
                Preuzmite ADS ekstenziju
            </Button>
        </div>
    );
};

export default AdsDownload;
