import React from "react";
//import circ from "../images/ads-circuit.png"
import AdsLogo from "../../assets/adslogo.svg";

const AdsTitle = () => {
    return (
        <div className="ads-intro valign-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <p>
                            <AdsLogo style={{ width: "100%" }} />
                        </p>
                    </div>
                    <div className="col-12 col-md-8 valign-wrapper">
                        <div className="hoverable" style={{ padding: "20px" }}>
                            <p>
                                Atamagai Digital Signature System (ADS) je
                                univerzalno rešenje za autentifikaciju korisnika
                                digitalnim sertifikatom unutar web portala i
                                potpisivanje dokumenata kvalifikovanim
                                elektronskim sertifikatima svih sertifikacionih
                                tela u Republici Srbiji.
                            </p>
                            <p>
                                Sistem se sastoji od posebnih mehanizama na
                                portalu koji koristi ADS, middleware aplikacije
                                za komunikaciju sa resursima lokalnog računara i
                                Chrome/Edge extenzije koja sve komponente
                                povezuje u jedinstvenu celinu.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img src={circ} style={{height: "250px"}} alt=""/> */}
        </div>
    );
};

export default AdsTitle;
