import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AdsTitle from "../components/ads/title";
import AdsDownload from "../components/ads/download";
import AdsDescription from "../components/ads/description";

const AdsPage = () => (
    <Layout isWhite={true} currentPage="ads">
        <SEO title="ADS" />

        <AdsTitle />        

        <div className="container">
            <div className="row">
                <div className="col-12 col-md-4">
                    <AdsDownload />
                </div>
                <div className="col-12 col-md-8">
                    <AdsDescription />
                </div>
            </div>
        </div>
    </Layout>
);

export default AdsPage;
